import React from "react";
import logo from "../img/icon_gradient_small.svg";
import { Link } from "react-router-dom";
import "../App.scss";

function header() {
  return (
    <Link to={"/"} title="home-link">
      <img src={logo} alt="neuragest logo" className="header"></img>
    </Link>
  );
}

export default header;
