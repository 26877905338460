import React, { useEffect } from "react";

function TermsOfUse() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <head>
        <title>Terms of Use | neuragest</title>
        <meta
          name="description"
          content="Terms of Use for neuragest exhibition"
        />
      </head>
      <div className="privacy">
        <h1>Website Terms of Use</h1>

        <p>
          The neuragest website located at https://neuragest.com/ is a
          copyrighted work belonging to neuragest. Certain features of the Site
          may be subject to additional guidelines, terms, or rules, which will
          be posted on the Site in connection with such features.
        </p>

        <p>
          All such additional terms, guidelines, and rules are incorporated by
          reference into these Terms.
        </p>

        <p>
          These Terms of Use described the legally binding terms and conditions
          that oversee your use of the Site. BY LOGGING INTO THE SITE, YOU ARE
          BEING COMPLIANT THAT THESE TERMS and you represent that you have the
          authority and capacity to enter into these Terms. YOU SHOULD BE AT
          LEAST 18 YEARS OF AGE TO ACCESS THE SITE. IF YOU DISAGREE WITH ALL OF
          THE PROVISION OF THESE TERMS, DO NOT LOG INTO AND/OR USE THE SITE.
        </p>

        <h2>Access to the Site</h2>

        <p>
          <strong>Subject to these Terms.</strong> Company grants you a
          non-transferable, non-exclusive, revocable, limited license to access
          the Site solely for your own personal, noncommercial use.
        </p>

        <p>
          <strong>Certain Restrictions.</strong> The rights approved to you in
          these Terms are subject to the following restrictions: (a) you shall
          not sell, rent, lease, transfer, assign, distribute, host, or
          otherwise commercially exploit the Site; (b) you shall not change,
          make derivative works of, disassemble, reverse compile or reverse
          engineer any part of the Site; (c) you shall not access the Site in
          order to build a similar or competitive website; and (d) except as
          expressly stated herein, no part of the Site may be copied,
          reproduced, distributed, republished, downloaded, displayed, posted or
          transmitted in any form or by any means unless otherwise indicated,
          any future release, update, or other addition to functionality of the
          Site shall be subject to these Terms.  All copyright and other
          proprietary notices on the Site must be retained on all copies
          thereof.
        </p>

        <p>
          Company reserves the right to change, suspend, or cease the Site with
          or without notice to you.  You approved that Company will not be held
          liable to you or any third-party for any change, interruption, or
          termination of the Site or any part.
        </p>

        <p>
          <strong>No Support or Maintenance.</strong> You agree that Company
          will have no obligation to provide you with any support in connection
          with the Site.
        </p>

        <p>
          Excluding any User Content that you may provide, you are aware that
          all the intellectual property rights, including copyrights, patents,
          trademarks, and trade secrets, in the Site and its content are owned
          by Company or Company's suppliers. Note that these Terms and access to
          the Site do not give you any rights, title or interest in or to any
          intellectual property rights, except for the limited access rights
          expressed in Section 2.1. Company and its suppliers reserve all rights
          not granted in these Terms.
        </p>

        <h2>Third-Party Links</h2>

        <p>
          <strong>Third-Party Links</strong> The Site may contain links to
          third-party websites and services, and/or display advertisements for
          third-parties.  Such Third-Party Links are not under the control of
          Company, and Company is not responsible for any Third-Party Links. 
          Company provides access to these Third-Party Links. When you click on
          any of the Third-Party Links, the applicable third party's terms and
          policies apply, including the third party's privacy and data gathering
          practices.
        </p>

        <p>
          <strong>Other Users.</strong> Each Site user is solely responsible for
          any and all of its own User Content.  Because we do not control User
          Content, you acknowledge and agree that we are not responsible for any
          User Content, whether provided by you or by others.  You agree that
          Company will not be responsible for any loss or damage incurred as the
          result of any such interactions.  If there is a dispute between you
          and any Site user, we are under no obligation to become involved.
        </p>

        <p>
          You hereby release and forever discharge the Company and our officers,
          employees, agents, successors, and assigns from, and hereby waive and
          relinquish, each and every past, present and future dispute, claim,
          controversy, demand, right, obligation, liability, action and cause of
          action of every kind and nature, that has arisen or arises directly or
          indirectly out of, or that relates directly or indirectly to, the
          Site.
        </p>

        <h2>Disclaimers</h2>

        <p>
          The site is provided on an "as-is" and "as available" basis, and
          company and our suppliers expressly disclaim any and all warranties
          and conditions of any kind, whether express, implied, or statutory,
          including all warranties or conditions of merchantability, fitness for
          a particular purpose, title, quiet enjoyment, accuracy, or
          non-infringement.  We and our suppliers make not guarantee that the
          site will meet your requirements, will be available on an
          uninterrupted, timely, secure, or error-free basis, or will be
          accurate, reliable, free of viruses or other harmful code, complete,
          legal, or safe.  If applicable law requires any warranties with
          respect to the site, all such warranties are limited in duration to
          ninety (90) days from the date of first use.
        </p>

        <p>
          Some jurisdictions do not allow the exclusion of implied warranties,
          so the above exclusion may not apply to you.  Some jurisdictions do
          not allow limitations on how long an implied warranty lasts, so the
          above limitation may not apply to you.
        </p>

        <h2>Copyright Policy.</h2>

        <p>
          Company respects the intellectual property of others and asks that
          users of our Site do the same.  In connection with our Site, we have
          adopted and implemented a policy respecting copyright law that
          provides for the removal of any infringing materials and for the
          termination of users of our online Site who are repeated infringers of
          intellectual property rights, including copyrights.  If you believe
          that one of our users is, through the use of our Site, unlawfully
          infringing the copyright(s) in a work, and wish to have the allegedly
          infringing material removed, the following information in the form of
          a written notification (pursuant to 17 U.S.C. § 512(c)) must be
          provided to our designated Copyright Agent:
        </p>

        <ul>
          <li>your physical or electronic signature;</li>
          <li>
            identification of the copyrighted work(s) that you claim to have
            been infringed;
          </li>
          <li>
            identification of the material on our services that you claim is
            infringing and that you request us to remove;
          </li>
          <li>sufficient information to permit us to locate such material;</li>
          <li>your address, telephone number, and e-mail address;</li>
          <li>
            a statement that you have a good faith belief that use of the
            objectionable material is not authorized by the copyright owner, its
            agent, or under the law; and
          </li>
          <li>
            a statement that the information in the notification is accurate,
            and under penalty of perjury, that you are either the owner of the
            copyright that has allegedly been infringed or that you are
            authorized to act on behalf of the copyright owner.
          </li>
        </ul>

        <p>
          Please note that, pursuant to 17 U.S.C. § 512(f), any
          misrepresentation of material fact in a written notification
          automatically subjects the complaining party to liability for any
          damages, costs and attorney's fees incurred by us in connection with
          the written notification and allegation of copyright infringement.
        </p>

        <h2>General</h2>

        <p>
          These Terms are subject to occasional revision, and if we make any
          substantial changes, we may notify you by sending you an e-mail to the
          last e-mail address you provided to us and/or by prominently posting
          notice of the changes on our Site.  You are responsible for providing
          us with your most current e-mail address.  In the event that the last
          e-mail address that you have provided us is not valid our dispatch of
          the e-mail containing such notice will nonetheless constitute
          effective notice of the changes described in the notice.  Any changes
          to these Terms will be effective upon the earliest of thirty (30)
          calendar days following our dispatch of an e-mail notice to you or
          thirty (30) calendar days following our posting of notice of the
          changes on our Site.  These changes will be effective immediately for
          new users of our Site.  Continued use of our Site following notice of
          such changes shall indicate your acknowledgement of such changes and
          agreement to be bound by the terms and conditions of such changes.
          Dispute Resolution. Please read this Arbitration Agreement carefully.
          It is part of your contract with Company and affects your rights.  It
          contains procedures for MANDATORY BINDING ARBITRATION AND A CLASS
          ACTION WAIVER.
        </p>

        <p>
          <strong>Copyright/Trademark Information.</strong> © neuragest. All
          rights reserved.  All trademarks, logos and service marks displayed on
          the Site are our property or the property of other third-parties. You
          are not permitted to use these Marks without our prior written consent
          or the consent of such third party which may own the Marks.
        </p>

        <h2>Contact Information</h2>
        <p>Email: info@neuragest.com</p>
      </div>
    </>
  );
}

export default TermsOfUse;
