import React from "react";
import { Link } from "react-router-dom";
import "../App.scss";

function footer() {
  return (
    <div className="footer">
      <div className="footer-menu">
        <Link className="link" title="home-link" to={"/"}>Home</Link>
        <Link className="link" title="privacy-policy-link" to={"/privacy-policy"}>Privacy Policy</Link>
        <Link className="link" title="terms-of-use-link" to={"/terms-of-use"}>Terms of Use</Link>
      </div>
      <p className="copyright">© neuragest 2024</p>
    </div>
  );
}

export default footer;
