import { addDoc, collection } from "firebase/firestore";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import "../App.scss";
import { db } from "../firebase";
import "../fonts/digitalGeometricBold.ttf";

function Home() {
  const [emailValue, setEmailValue] = useState("");
  const [nameValue, setNameValue] = useState("");
  const [checkValue, setCheckValue] = useState(false);
  const [isValidEmail, setIsValidEmail] = useState(false);
  const [isValidName, setIsValidName] = useState(false);
  const [isSent, setIsSent] = useState(false);
  const [messageText, setMessageText] = useState("");

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const getEmailValue = (event) => {
    event.preventDefault();

    const email = emailValue;
    const name = nameValue;
    const check = checkValue;

    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    if (name === "" || name === null || name === undefined) {
      setIsSent(false);
      setMessageText("Please enter your name.");
      setTimeout(() => {
        setMessageText("");
      }, 2000);
    } else if (!emailRegex.test(email)) {
      setIsSent(false);
      setMessageText("Please enter a valid email address.");
      setTimeout(() => {
        setMessageText("");
      }, 2000);
    } else if(!check){
      setIsSent(false);
      setMessageText("Please read and agree to neuragest's Privacy Policy and Terms of Use.");
      setTimeout(() => {
        setMessageText("");
      }, 4000);
    } else {
      setIsValidName(true);
      setIsValidEmail(true);
      setIsSent(true);
      let date = new Date().toDateString();
      addData(email, name, date, check);
      setEmailValue("");
      setNameValue("");
      setCheckValue(false);
      setMessageText("You've successfully subscribed!");
      setTimeout(() => {
        setMessageText("");
      }, 3000);
    }
  };

  const addData = async (email, name, date, check) => {
    try {
      const docRef = await addDoc(collection(db, "emails"), {
        email: email,
        name: name,
        date: date,
        check: check,
      });
      console.log("Document written widh ID: ", docRef.id);
    } catch (e) {
      console.log("Error adding document: ", e);
    }
  };

  document.addEventListener("mousemove", function (e) {
    const bg = document.querySelector(".AppBg");
    const screenWidth = window.innerWidth;
    const screenHeight = window.innerHeight;
    const moveX = (screenWidth / 2 - e.pageX) / 100;
    const moveY = (screenHeight / 2 - e.pageY) / 50;

    bg.style.transform = "translate(" + moveX + "px, " + moveY + "px)";
  });

  return (
    <>
      <title>neuragest</title>
      <meta
        name="description"
        content="Enter this AI-powered experience, and create art with every gesture.
      neuragest, developed by Marc López."
      />

      <div className="App">
        <div className="AppBg"></div>
        <div className="info">
          <div className="text">
            <div className="intro">
              <h1>
                Welcome to <span className="neuragest">neuragest</span>
              </h1>
              <h2>
                Enter this AI-powered experience, and create art with every
                gesture.
              </h2>
            </div>
            <iframe
              src="https://www.youtube.com/embed/fGLWp5k7ig8?si=t8POkJlJFv3i5P3p"
              title="YouTube video player"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share; fullscreen"
            ></iframe>
          </div>
          <div className="newsletter">
            <p className="info_text">
              This project is under development.<br></br>Leave your email and
              we'll keep you updated.
            </p>
            <form className="form">
              <div>
                <input
                  name="name"
                  type="text"
                  className="input"
                  placeholder="Your name"
                  value={nameValue}
                  onChange={(e) => setNameValue(e.target.value)}
                  required
                />
                <input
                  name="email"
                  type="email"
                  className="input"
                  placeholder="Email address"
                  value={emailValue}
                  onChange={(e) => setEmailValue(e.target.value)}
                  required
                />
                <p className="legal">
                  <input type="checkbox" checked={checkValue} onChange={(e) => setCheckValue(e.target.checked)}/>
                  I have read and agree to neuragest's{" "}
                  <Link to={"/privacy-policy"}>Privacy Policy</Link> and{" "}
                  <Link to={"/terms-of-use"}>Terms of Use</Link>
                </p>
                <button className="button" onClick={getEmailValue}>
                  <span>Sign Up</span>
                </button>
                <p className={`message ${isSent ? "sent" : "error"}`}>
                  {messageText}
                </p>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
}

export default Home;
